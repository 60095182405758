export default {
    name: 'cloud-order-detail',
    props:{
        // 订单数据
        order:{
            type: Array,
            default: () => []
        }
    },
    data(){
        return{

        }
    }
}