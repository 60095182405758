export default {
    name: 'outWare',
    props: {
        show: {
            type: Boolean,
            default: () => false
        },
        order:{
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            value: 0
        }
    },
    watch:{
        order(){
            const list =  this.order.productList.map(item =>{
                item.sum = 0
                return item
            })
            this.order.productList = list
        }
    },
    methods: {
        // 选择出库
        onConfirm() {
            let { order } = this;
            const pList = order.productList.filter(item => item.sum != 0)

            if(pList.length <= 0) return this.$toast({ message: '出库商品数量为0', className: 'toastIndex'})

            let orders = {
                code: order.code,
                productList: pList
            }
            this.$router.push({
                name: 'cloudAddress',
                query: {
                    order: encodeURI(JSON.stringify(orders))
                }
            })
        },
        // 改变计步器
        onChangeStepper(val, id){
            let { order } = this;
            order.productList.forEach(item =>{
                if(item.productID == id) item.sum = val
            })
            this.order = order
        },
        // 点击关闭
        onClose(){
            this.$emit('close')
        }
    }
}