import { HttpV1 as HTTP } from "@/request";

// 获取云仓库订单列表
export async function getYunStoreList(params) {
  return await HTTP.post('/agent/yun-stock/list', params);
}
// 云仓库单出库
export async function outYunStore(params) {
  return await HTTP.post('/agent/yun-stock/out', params);
}
// 出库记录列表
export async function outRecordList(params) {
  return await HTTP.post('/agent/yun-stock/outRecordList', params);
}
// 更新云仓库状态
export async function updateState(params) {
  return await HTTP.get('/agent/yun-stock/updateState', params);
}
