import { mapState } from 'vuex';
import OutWare from './components/outWare/index.vue';
import { getYunStoreList } from '@/service/cloudWarehouse/index';
import CloudOrderDetail from '@/components/order/cloud-order-detail/index.vue';
import MonthPicker from '@/components/rebate/month-picker/index.vue';

export default {
    name: 'warehouse',
    components:{
        OutWare,
        CloudOrderDetail,
        MonthPicker
    },
    data() {
        return {
            tab: [
                { 
                    name: '云库存订单', 
                    icon: require('@/assets/images/cloudWarehouse/tab1-unActive.png'),
                    icon2: require('@/assets/images/cloudWarehouse/tab1-active.png')
                },
                { 
                    name: '云历史订单', 
                    icon: require('@/assets/images/cloudWarehouse/tab2-unActive.png'),
                    icon2: require('@/assets/images/cloudWarehouse/tab2-active.png')
                }
            ],
            orderData:[],
            activeTab: 0,
            curDate: new Date(), // 日期
            showPopup: false, // 出库
            finished: false, // 是否已经加载完成
            loading: true, // 是否处于加载状态
            curRow: {}, //当前选择出库数据
            offset: 1,
        }
    },
    computed:{
        ...mapState({
            userId: state => state.user.user.id,
            brandId: status => status.global.brandId
        }),
    },
    mounted(){
        let { date } = this.$route.query;
        if(date) this.curDate = new Date(JSON.parse(date))
        this.init()
    },
    methods: {
        // 初始化
        async init(){
            this.offset = 1
            this.orderData = []
            this.getOrderList()
        },
        // 获取列表
        async getOrderList(){
            let { userId, activeTab, brandId, curDate, offset, orderData } = this,
            y = curDate.getFullYear(),
            m = curDate.getMonth() + 1,
            d = new Date(y, m, 0).getDate(),
            params = {
                userId,
                systemId: 1,
                type: activeTab == 0? 1:2,
                limit: 10,
                offset: (offset - 1) * 10,
                // startTime: `${y}-${m}-01 00:00:00`,
                // endTime: `${y}-${m}-${d} 23:59:59`
            }

            const res = await getYunStoreList(params)
            this.loading = false
            this.orderData = [...orderData, ...res]

            if (res.length < 10) this.finished = true
        },
        // 切换tab
        onChangeTab(index){
            this.activeTab = index
            this.init()
        },
        // 点击显示、关闭showPopup
        onClose(){
            this.showPopup = !this.showPopup
        },
        // 出库单按钮
        onCheck(row){
            this.$router.replace({ 
                name: 'warehouse',
                query:{ date: JSON.stringify(this.curDate) }
            })
            this.$router.push({ 
                name: 'outOrder',
                query:{ code: row.code }
            })
        },
        // 点击出库
        onOut(row){
            this.curRow = row
            this.onClose()
        },
        // 选择日期
        onSelectedDate(date){
            this.curDate = date
            this.init()
        },
        // 滚动加载
        onLoad(){
            this.offset ++
            this.getOrderList()
        },
    }
}  